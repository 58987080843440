<template>
  <div class='support-page'>
    <div class='support-cont'>
      <div class='support-form'>
        <h2 class='header-label'>Support</h2>
        <hr>
        <div>
          <div class='input-row just-cont-fs' v-bind:class="{'flex-direction-column': this.$store.getters.getIsMobile}">
            <div>
              <input id='first-name' placeholder='first name' />
              <label class='d-flex' for='first-name'>first name</label>
            </div>
            <div>
              <input id='last-name' placeholder='last name' />
              <label class='d-flex' for='last-name'>last name</label>
            </div>
          </div>
          <div class='input-row just-cont-fs' v-bind:class="{'flex-direction-column': this.$store.getters.getIsMobile}">
            <div>
              <input id='email' placeholder='email' />
              <label class='d-flex' for='email'>email</label>
            </div>
          </div>
          <div class='input-row just-cont-fs' v-bind:class="{'flex-direction-column': this.$store.getters.getIsMobile}">
            <div>
              <textarea id='description' placeholder='description' />
              <label class='d-flex' for='email'>description</label>
            </div>
          </div>
          <div class='d-flex' v-bind:class="{'justify-center': this.$store.getters.getIsMobile}">
            <button class="btn btn-update">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.support-page {
  display: flex;
  justify-content: center;
}

.support-cont {
  width: 85%;
  overflow: auto;
  height: 620px;
  left: 118px;
  top: 178px;

  background: var(--background-color-secondary);
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.25);
}

.support-cont hr {
  border: 0.25px solid var(--accent-color);
  width: 95%;
}

.input-row {
  display: flex;
  align-items: center;
  padding: 15px;
}

.input-row textarea {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  padding: 10px;
  margin: 10px;
  width: 250px;
  height: 127px;
  border-radius: 5px;
  border-color: #00A4FF;
}

textarea:focus {
  outline: none !important;
  border-color: #00A4FF;
}

.just-cont-fs {
  justify-content: flex-start;
}

.header-label {
  display: flex;
  font-weight: 100;
  margin-left: 25px;
}

.btn:hover {
  color: #00A4FF;
  background-color: var(--background-color-primary);
  border: 1px solid #00A4FF;
  cursor: pointer;
}

.btn:active {
  border: 1px solid #00A4FF;
  background-color: #00A4FF;
  color: white;
}

.btn-update {
  width: 240px;
  height: 39px;
  top: 610px;
  margin: 10px 25px;
  font-size: 18px;
}
</style>